import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic';
import isDarkColor from 'is-dark-color';
import React, { FormEvent, useEffect, useState } from 'react';
import { ReactComponent as LockIcon } from '../assets/icons/lock.svg';
import { Button } from '../components/Button';
import { Callout, calloutLayout } from '../components/Callout';
import { EaseIn } from '../components/EaseIn';
import { Footer } from '../components/Footer';
import { Gallery } from '../components/Gallery';
import { Header } from '../components/Header';
import { Headline } from '../components/Headline';
import { HeadlineImage } from '../components/HeadlineImage';
import { InputForm } from '../components/InputForm';
import { JournalList } from '../components/JournalList';
import { MasonryGallery } from '../components/MasonryGallery';
import { Media } from '../components/Media';
import { Meta } from '../components/Meta';
import { Paragraph, paragraphLayout } from '../components/Paragraph';
import { ProjectList } from '../components/ProjectList';
import { Resources } from '../components/Resources';
import { ScrollCarousel } from '../components/ScrollCarousel';
import { Section } from '../components/Section';
import { Separator } from '../components/Separator';
import { Tags } from '../components/Tags';
import { TitleParagraph } from '../components/TitleParagraph';
import { Whisper } from '../components/Whisper';
import { useInverted } from '../lib/hooks';
import { darkBackgrounds, setProperties } from '../lib/utils';
import { BP, setAlignment, subPageGrid, TYPESET } from '../styles/mixins';
import { PrivatePage } from './partials/private';

export default withPreview(PageTemplate as any);
function PageTemplate({ data: query }: { data: GatsbyTypes.PageQuery }) {
  const { data, tags, prismicId } = query.prismicProject!,
    [authenticated, setAuthenticated] = useState(false),
    date = data?.date && new Date(data.date),
    darkHeader =
      data?.header_background_colour &&
      isDarkColor(data.header_background_colour);

  if (!data) {
    return null;
  }

  useInverted(!!data.inverted);
  useEffect(() => {
    setProperties({
      '--color-accent': data.accent_color || 'var(--color-accent-light)',
      '--color-background': data.background_color
    });
  }, [data]);

  return data.private && !authenticated ? (
    <PrivatePage
      docId={prismicId}
      onAuthenticate={(authed: boolean) => setAuthenticated(authed)}
    />
  ) : (
    <>
      <Meta
        title={data.meta_title}
        description={data.meta_description}
        cover={data.social_image ? data.social_image.url : ''}
      />
      <Header
        css={
          data.header_background_colour &&
          css`
            background: ${data.header_background_colour};
          `
        }
        title={data.title ? data.title.text : ''}
        noMenu={data.private}
        inverted={darkHeader}
      />
      {(date || !!tags.length) && (
        <div
          css={[
            css`
              ${subPageGrid}
              ${TYPESET.scale0}
              padding: calc(80px - var(--margin)) 0 var(--padding);
              @media (${BP.xs}) {
                padding-left: 12px;
                padding-right: 12px;
              }
            `,
            data.header_background_colour &&
              css`
                background: ${data.header_background_colour};
              `,
            darkHeader &&
              css`
                color: var(--color-primary-dark);
              `
          ]}
        >
          <div>
            {date && (
              <span
                css={css`
                  display: block;
                  font-weight: var(--font-weight-bold);
                `}
              >
                {date?.getFullYear()}
              </span>
            )}
            {!!tags.length && <Tags tags={tags as string} />}
          </div>
        </div>
      )}
      {data.body &&
        data.body.map(({ primary, items, slice_type }: any, i: number) => {
          const slicePadding =
            primary?.padding && primary.padding.toLowerCase();

          switch (slice_type) {
            case 'headline':
              return (
                <Section
                  spacing={slicePadding}
                  background={[
                    primary.background_start,
                    primary.background_end
                  ]}
                  key={i}
                >
                  <Headline
                    headline={primary.headline.html}
                    alignment={primary.alignment}
                    large={primary.size === 'large'}
                  />
                </Section>
              );
            case 'paragraph':
              return (
                <Section
                  spacing={slicePadding}
                  background={[
                    primary.background_start,
                    primary.background_end
                  ]}
                  key={i}
                >
                  <Paragraph
                    css={setAlignment(primary.alignment)}
                    content={primary.content.html}
                  />
                </Section>
              );
            case 'title_paragraph':
              return (
                <Section
                  spacing={slicePadding}
                  background={[
                    primary.background_start,
                    primary.background_end
                  ]}
                  key={i}
                >
                  <TitleParagraph
                    title={primary.paragraph_title.html}
                    content={primary.content.html}
                  />
                </Section>
              );
            case 'introduction':
              return (
                <Section
                  spacing={slicePadding}
                  background={[
                    primary.background_start,
                    primary.background_end
                  ]}
                  key={i}
                >
                  <Callout
                    css={setAlignment(primary.alignment)}
                    content={primary.content.html}
                    leftAlign
                  />
                </Section>
              );
            case 'announcement':
              return (
                <Section
                  spacing={slicePadding}
                  background={[
                    primary.background_start,
                    primary.background_end
                  ]}
                  key={i}
                >
                  <Callout
                    css={setAlignment(primary.alignment)}
                    content={primary.content.html}
                    large
                    leftAlign
                  />
                </Section>
              );
            case 'whisper':
              return (
                <Section
                  spacing={slicePadding}
                  background={[
                    primary.background_start,
                    primary.background_end
                  ]}
                  key={i}
                >
                  <Whisper content={primary.content.html} />
                </Section>
              );
            case 'media':
              const MediaWrapper = !!primary.video ? 'div' : EaseIn,
                mediaProps = {
                  video: primary.video?.embed_url || '',
                  image: primary.image,
                  mobileImage: primary.mobile_image,
                  caption: primary.caption.html,
                  captionAlign: primary.caption_alignment
                };
              return (
                <Section
                  spacing={slicePadding}
                  background={[
                    primary.background_start,
                    primary.background_end
                  ]}
                  fullWidth={primary.width === 'Fullscreen'}
                  key={i}
                >
                  {primary.width === 'fullscreen' ? (
                    <Media {...mediaProps} rounded={false} />
                  ) : (
                    <MediaWrapper
                      css={[
                        css`
                          grid-column: 1 / -1;
                        `,
                        primary.width === 'Paragraph' && paragraphLayout,
                        primary.width === 'Headline' && calloutLayout
                      ]}
                    >
                      <Media {...mediaProps} />
                    </MediaWrapper>
                  )}
                </Section>
              );
            case 'gallery':
              return (
                <Section
                  spacing={slicePadding}
                  background={[
                    primary.background_start,
                    primary.background_end
                  ]}
                  key={i}
                >
                  <Gallery
                    large={primary.large}
                    items={items.map(
                      ({ caption, caption_alignment, image }: any) => ({
                        caption_alignment,
                        caption,
                        ...image
                      })
                    )}
                  />
                </Section>
              );
            case 'masonry_gallery':
              return (
                <Section
                  spacing={slicePadding}
                  background={[
                    primary.background_start,
                    primary.background_end
                  ]}
                  key={i}
                >
                  <MasonryGallery
                    columns={primary.columns}
                    items={items.map(({ image, video }: any) => ({
                      image,
                      video: video?.embed_url
                    }))}
                  />
                </Section>
              );
            case 'carousel':
              return (
                <Section
                  spacing={slicePadding}
                  background={[
                    primary.background_start,
                    primary.background_end
                  ]}
                  key={i}
                >
                  <ScrollCarousel
                    title={primary.heading.text}
                    items={
                      items && items.map(({ image }: any) => ({ ...image }))
                    }
                  />
                </Section>
              );
            case 'headline_image':
              return (
                <Section spacing={slicePadding} key={i} fullWidth>
                  <HeadlineImage
                    headline={primary.headline.html}
                    large={primary.size === 'large'}
                    image={primary.image}
                    mobileImage={primary.mobile_image}
                    inverted={primary.inverted}
                  />
                </Section>
              );
            case 'project_list':
              return (
                <Section
                  spacing={slicePadding}
                  background={[
                    primary.background_start,
                    primary.background_end
                  ]}
                  key={i}
                >
                  <ProjectList
                    title={primary.projects_title.text}
                    start={primary.start_limit}
                    end={primary.end_limit}
                    docId={prismicId}
                  />
                </Section>
              );
            case 'journal_list':
              return (
                <Section
                  spacing={slicePadding}
                  background={[
                    primary.background_start,
                    primary.background_end
                  ]}
                  css={css`
                    grid-row-gap: var(--margin);
                  `}
                  key={i}
                >
                  <JournalList
                    title={primary.journal_title.text}
                    start={primary.start_limit}
                    end={primary.end_limit}
                    docId={prismicId}
                    background={[
                      primary.background_start,
                      primary.background_end
                    ]}
                  />
                </Section>
              );
            case 'button':
              return (
                <Section
                  spacing={slicePadding}
                  background={[
                    primary.background_start,
                    primary.background_end
                  ]}
                  key={i}
                >
                  <div
                    css={css`
                      grid-column: 1 / -1;
                      text-align: center;
                    `}
                  >
                    <Button
                      href={primary.button_link.url}
                      theme={primary.button_style}
                      color={primary.button_color}
                    >
                      {primary.button_label}
                    </Button>
                  </div>
                </Section>
              );
            case 'resources':
              return (
                <Section
                  spacing={slicePadding}
                  background={[
                    primary.background_start,
                    primary.background_end
                  ]}
                  key={i}
                >
                  <Resources
                    darkTheme={darkBackgrounds([
                      primary.background_start,
                      primary.background_end
                    ])}
                    items={items}
                  />
                </Section>
              );
            case 'spacer':
              return (
                <Section
                  background={[primary.background]}
                  css={css`
                    padding-bottom: 0;
                  `}
                  key={i}
                />
              );
            case 'separator':
              return (
                <Section
                  spacing={slicePadding}
                  background={[
                    primary.background_start,
                    primary.background_end
                  ]}
                  fullWidth={primary.width === 'Fullscreen'}
                  key={i}
                >
                  <Separator
                    css={[
                      css`
                        grid-column: 1 / -1;
                      `,
                      primary.width === 'Paragraph' && paragraphLayout,
                      primary.width === 'Headline' && calloutLayout
                    ]}
                    theme={primary.theme}
                  />
                </Section>
              );
          }
        })}

      {data.footer && <Footer />}
    </>
  );
}

export const query = graphql`
  query Page($uid: String!) {
    prismicProject(uid: { eq: $uid }) {
      _previewable
      prismicId
      tags
      data {
        footer
        private
        meta_title
        meta_description
        social_image {
          url
        }
        title {
          text
        }
        description {
          text
        }
        date
        accent_color
        background_color
        header_background_colour
        inverted
        body {
          ... on PrismicProjectBodyHeadline {
            slice_type
            primary {
              alignment
              padding
              background_start
              background_end
              size
              headline {
                html
              }
            }
          }
          ... on PrismicProjectBodyParagraph {
            slice_type
            primary {
              alignment
              padding
              background_start
              background_end
              content {
                html
              }
            }
          }
          ... on PrismicProjectBodyIntroduction {
            slice_type
            primary {
              alignment
              padding
              background_start
              background_end
              content {
                html
              }
            }
          }
          ... on PrismicProjectBodyAnnouncement {
            slice_type
            primary {
              alignment
              padding
              background_start
              background_end
              content {
                html
              }
            }
          }
          ... on PrismicProjectBodyWhisper {
            slice_type
            primary {
              padding
              background_start
              background_end
              content {
                html
              }
            }
          }
          ... on PrismicProjectBodyMedia {
            slice_type
            primary {
              padding
              background_start
              background_end
              image {
                url
                alt
                fluid(maxWidth: 5480) {
                  ...GatsbyPrismicImageFluid
                }
              }
              mobile_image {
                url
                alt
                fluid(maxWidth: 1920) {
                  ...GatsbyPrismicImageFluid
                }
              }
              video {
                embed_url
              }
              caption {
                html
              }
              caption_alignment
              width
            }
          }
          ... on PrismicProjectBodyGallery {
            slice_type
            primary {
              padding
              background_start
              background_end
              large
            }
            items {
              caption {
                html
              }
              caption_alignment
              image {
                url
                alt
                fluid(maxWidth: 5480) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicProjectBodyMasonryGallery {
            slice_type
            primary {
              padding
              background_start
              background_end
              columns
            }
            items {
              image {
                url
                alt
                fluid(maxWidth: 5480) {
                  ...GatsbyPrismicImageFluid
                }
              }
              video {
                embed_url
              }
            }
          }
          ... on PrismicProjectBodyCarousel {
            slice_type
            primary {
              heading {
                text
              }
              padding
              background_start
              background_end
            }
            items {
              image {
                url
                alt
                fluid(maxWidth: 5480) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicProjectBodyHeadlineImage {
            slice_type
            primary {
              padding
              size
              headline {
                html
              }
              image {
                url
                alt
                fluid(maxWidth: 5480) {
                  ...GatsbyPrismicImageFluid
                }
              }
              mobile_image {
                url
                alt
                fluid(maxWidth: 1920) {
                  ...GatsbyPrismicImageFluid
                }
              }
              inverted
            }
          }
          ... on PrismicProjectBodyResources {
            slice_type
            primary {
              padding
              background_start
              background_end
            }
            items {
              resource_name
              resource_thumbnail {
                url
                alt
                fluid(maxWidth: 64) {
                  ...GatsbyPrismicImageFluid
                }
              }
              resource_format
              resource_file {
                url
              }
            }
          }
          ... on PrismicProjectBodyTitleParagraph {
            slice_type
            primary {
              padding
              paragraph_title {
                html
              }
              content {
                html
              }
              background_start
              background_end
            }
          }
          ... on PrismicProjectBodyProjectList {
            slice_type
            primary {
              projects_title {
                text
              }
              start_limit
              end_limit
              padding
              background_start
              background_end
            }
          }
          ... on PrismicProjectBodyJournalList {
            slice_type
            primary {
              journal_title {
                text
              }
              start_limit
              end_limit
              padding
              background_start
              background_end
            }
          }
          ... on PrismicProjectBodyButton {
            slice_type
            primary {
              button_label
              button_link {
                url
              }
              button_style
              button_color
              padding
              background_start
              background_end
            }
          }
          ... on PrismicProjectBodySeparator {
            slice_type
            primary {
              padding
              theme
              width
              background_start
              background_end
            }
          }
          ... on PrismicProjectBodySpacer {
            slice_type
            primary {
              background
            }
          }
        }
      }
    }
  }
`;
